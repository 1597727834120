import React from 'react';
import "../css/WUC.css";

class WUC extends React.Component {

	render() {

		return (
			<div className="container-wuc wuc">
			<div className="background-image"></div>
			<div className="content">
				<h1 className="heading">Under Construction</h1>
				<p className="message">
				The web builder is working hard to bring you a website.
				</p>
			</div>
			</div>
		);
	}
}

export default WUC;