import React from 'react';
import './css/App.css';
// import Project from './components/Project';
// import Content from './components/Content';
// import Home from './components/Home';
// import NavLogo from './components/NavLogo';
import WUC from './components/WUC';


class App extends React.Component {

	// constructor(props) {
	// 	super(props);
	// 	const activeItem = localStorage.getItem("activeItem") || "home";
	// 	this.state = {
	// 	  activeItem: activeItem,
	// 	  title: "Home"
	// 	};
	//   }
	
	//   handleItemClick = (item) => {
	// 	// Save the active item to localStorage when it changes
	// 	localStorage.setItem("activeItem", item);
	// 	this.setState({ activeItem: item });
	//   };

    render() {
		// let content = null;
		// if (this.state.activeItem === "home") {
		// 	content = <Home />;
		// 	if (this.state.title !== "Home") {this.setState({title: "Home"})};
		// } else if (this.state.activeItem === "about-us") {
		// 	content = <Content />;
		// 	if (this.state.title !== "About us") {this.setState({title: "About us"})};
		// } else if (this.state.activeItem === "projects") {
		// 	content = <Project />;
		// 	if (this.state.title !== "Projects") {this.setState({title: "Projects"})};
		// }

        return (
			<div className='page'>
				{/* <div className='navbar'>
					<NavLogo img={process.env.PUBLIC_URL + "logo192.png"} height="32" width="32" />
					<h1 className='pagetitle'>{this.state.title}</h1>
					<button 
						className={this.state.activeItem === "home" ? "active" : ""}
						onClick={() => this.handleItemClick("home")}>Home</button>
					<button 
						className={this.state.activeItem === "projects" ? "active" : ""}
						onClick={() => this.handleItemClick("projects")}>Projects</button>
					<button 
						className={this.state.activeItem === "about-us" ? "active" : ""}
						onClick={() => this.handleItemClick("about-us")}>About us</button>
					
				</div>
				<div className='content'>
					{content}
				</div> */}
				<WUC />
		  	</div>
        )
    }
}

export default App;
